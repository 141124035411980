



import React from 'react';
import './App.css';

class SidePanel extends React.Component {
  render() {
    const { title, content, onRequestClose, enabled } = this.props;

    return (
      <div>
        <div
          className={`modal-background ${enabled ? 'enabled' : 'disabled'}`}
          onClick={onRequestClose}
        />
        <div className={`filter-panel ${enabled ? '' : 'disabled'}`}> 
          <div className="modal-header">
            <div className="title">{title}</div>
            <button onClick={onRequestClose} className="modal-button">{enabled ? '✕' : '+'}</button>
          </div>
          {enabled ? content : <div />}
        </div>
      </div>
    );
  }
}

export default SidePanel;
