import React from 'react';
import './App.css';
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <NavLink
          className='App-header-row'
          to='/privacypolicy'
          style={{ fontSize: '14px' }}
        >
          Privacy Policy and Disclaimer
        </NavLink>
      </footer>
    );
  }
}

export default Footer;
