import React from 'react';
import './App.css';
import BlogEntry from './BlogEntry';
import { agg_issues, calc_sum, calc_avg } from './Common';

const OMNIBUS_LIST = [
  {
    'title': 'Crimson Reign Omnibus',
    'summary': `This story builds on the Qi'ra and Crimson Dawn plot introduced in War of the Bounty Hunters. The Crimson Reign miniseries shows how Qi'ra built her power base and recruited allies to prepare for her fight against the Empire. All the tie-ins from ongoing series show the impact that the return of Crimson Dawn has cross the galaxy. Although this is called a crossover, it is far less interconnected than War of the Bounty Hunters and the stories in each series are pretty independent. 

    The Star Wars issues are really good, with a mix of both Luke's adventures learning more about the Jedi and a Leia story where she faces off against Commander Zahra. The Bounty Hunters issues were also great, including an issue focused on Dengar that was pretty memorable. The Doctor Aphra issues are where the series started to find it's footing for me, and introduce the Spark Eternal storyline that continues through the Hidden Empire event. The Vader issues include Sabe's return to the series as the Sith Lord attempts to identify traitors loyal to Crimson Dawn.

    The story arcs in this omnibus are all solid and the writer's of the ongoing series all did an excellent job using the return of Crimson Dawn to drive their own stories forward. My only complaint is that collections like these make it very difficult to collect all issues of the ongoing series in hardcover without any double dipping. However, being able to read the entire crossover together in a single book can be a great reading experience, and the gaps before or after event hardcovers can always be filled with paperbacks. I am really happy they continued Qi'ra's story in the comics so I'll definitely be picking this up to complement the War of the Bounty Hunters omnibus. 
    `,
    'amazon': 'https://amzn.to/3LStEo8',
    'release_date': '2025-04-08',
    'included_issues': [
      663,
      680,
      681,
      664,
      665,
      892,
      893,
      894,
      895,
      896,
      897,
      898,
      682,
      683,
      675,
      676,
      677,
      678,
      679,
      899,
      900,
      666,
      667,
      668,
      669,
      670,
      671,
    ],
    'img': 800009,
  },
  {
    'title': 'Star Wars by Gillen & Pak Omnibus',
    'summary': `In this collection Gillen proves once again how incredible of a Star Wars writer he is. He elegantly weaves plots throughout the series, with every issue having some added significance later in the story.
      
      It opens with a return to Jedha in the wake of it's partial destruction in Rogue One. It feels so right seeing Han, Luke, and Leia on Jedha that it feels like this story was always meant to be told. The rest of his arcs gave a similar feeling, each one so authentically Star Wars that it feels like a movie. In addition to connections to Rogue One, this series also references characters from The Clone Wars and Rebels that really help tie the universe together in an organic way.
  
      Pak's story arc towards the end is good enough, but reading it right after the brilliant climax of Gillen's run just emphasizes how average it is. At least it has Noto's unique art style to help maintain our attention. On the topic of art, it must also be mentioned that the artwork is incredibly weak in the first half of Gillen's run. It's quite tragic that the such a strong writer didn't get an artist of a similar calibre, but at least the second half of his run had much better artistic talent. However, it must be emphasized that the last half of Gillen's run is peak Star Wars and those issues alone make this collection worth buying.
      `,
    'amazon': 'https://amzn.to/3yx9lJH',
    'release_date': '2024-04-23',
    'included_issues': [
      526,
      527,
      528,
      529,
      530,
      531,
      532,
      533,
      534,
      535,
      536,
      537,
      538,
      539,
      540,
      541,
      542,
      543,
      544,
      545,
      546,
      547,
      548,
      549,
      550,
      551,
      552,
      553,
      554,
      555,
      556,
      567,
      568,
      569,
      570,
      571,
      572,
      573,
      574,
      424,
      1032,
    ],
    'img': 800002,
  },
  {
    'title': 'The High Republic Omnibus Vol. 2',
    'summary': `Coming in at just 14 issues, this is the smallest Star Wars collection with the Omnibus branding. These stories cover Phase 2 of the High Republic, including the main comic series and The High Republic: The Blade miniseries. 
      
      The main High Republic series has a pretty good story with excellent artwork. Although I wouldn't say you need to read any novels before this series, the story directly continues in the young adult novel Path of Vengeance. I would highly recommend picking up the novel to get a satisfying conclusion. 
      
      The Blade miniseries tells a great stand-alone story about Porter Engle many years before his appearance in the Light of the Jedi novel. It's an excellent read that packed a lot of action and emotion into just four issues. It also sets up a plot point continued in the novel The High Republic: The Eye of Darkness.`,
    'amazon': 'https://amzn.to/3WTcOfy',
    'release_date': '2024-11-12',
    'included_issues': [
      945,
      946,
      947,
      948,
      949,
      950,
      951,
      952,
      953,
      954,
      955,
      956,
      957,
      958,
    ],
    'img': 800007,
  },
  {
    'title': 'The High Republic Omnibus Vol. 1',
    'summary': `Kicking off a brand new era of Star Wars storytelling, this collects all the Marvel High Republic Phase 1 comics. Some fans were disappointed the Dark Horse High Republic issues weren't included, but a cross-publisher omnibus is incredibly rare and I much preferred the Marvel issues anyways. 
      
      What is required in addition to this omnibus, however, is the novels that establish many of the characters and plot points that are featured in these comics. There is no better way to start the High Republic than by reading the novel Light of the Jedi, which is an incredible introduction to the era. That is the only novel I'd say is strictly necessary reading, however I'd also recommend Into the Dark because it introduces a villain that appears frequently in this omnibus, and The Rising Storm because it's fantastic.
      
      Beyond the interdependence on other media, these are unique and fun stories to read. It's so refreshing to get stories about new characters in a new time period where we don't already know all of the details of how everything ends and who lives or dies. If you have no interest in reading books I'd recommend choosing a different omnibus, but it really looks incredible on the shelf next to Light of the Jedi.`,
    'amazon': 'https://amzn.to/44V4rSK',
    'release_date': '2023-10-17',
    'included_issues': [
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      24,
      26,
      33,
      34,
      35,
      42,
      31,
      36,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
    ],
    'img': 800006,
  },
  {
    'title': 'War of the Bounty Hunters Omnibus',
    'summary': `This omnibus collects Marvel's first major Star Wars crossover event, War of the Bounty Hunters. In the aftermath of Episode V: The Empire Strikes Back, Boba Fett is now in possession of Han Solo frozen in carbonite. Before he can deliver the bounty to Jabba's Palace however, it's stolen from him and he must compete with Darth Vader, Doctor Aphra, the Rebellion, and various other bounty hunters to get it back. 
  
      The issues from the main miniseries are excellent and tell a great story. Without giving too many spoilers, it follows up on some plots from a movie that I never expected to see in comics. The best stories are the ones that tie together various eras of the Star Wars universe in a natural way, and I'd say that's pulled off very well here. 
  
      The tie-in issues vary in quality, ranging from decent to amazing. For the most part you can enjoy them even without reading the prior issues, but there are quite a few new characters that were introduced in the earlier issues. For example, Valence is the main character of the Bounty Hunters series, so for someone who hasn't been reading it they may be a bit lost reading the issues in this collection. 
  
      Although this omnibus collects the entire event, some of the plot points introduced in this story are continued in the Crimson Reign and Hidden Empire miniseries, with the Crimson Reign omnibus available for pre-order and no Hidden Empire omnibus announced yet. The only word of caution I'd provide about this collection is that it presents a risk of double-dipping with future collections. For example, if you buy this and the Doctor Aphra Omnibus vol. 2, you'll own two copies of Doctor Aphra (2020) 10-15. However, this is currently the only way to get the main War of the Bounty Hunter issues in oversized hardcover and it is a great reading experience to have all the tie-ins together with it in one collection. 
      `,
    'amazon': 'https://amzn.to/3QX9XP4',
    'release_date': '2022-12-13',
    'included_issues': [
      628,
      629,
      630,
      631,
      632,
      633,
      634,
      635,
      636,
      637,
      638,
      639,
      640,
      641,
      642,
      643,
      644,
      645,
      646,
      647,
      648,
      649,
      650,
      651,
      652,
      653,
      654,
      655,
      656,
      657,
      658,
      659,
      660,
      661,
      662,
    ],
    'img': 800008,
  },
  {
    'title': 'Doctor Aphra Omnibus Vol. 2',
    'summary': `This volume continues Doctor Aphra adventures in the period between Episode V: The Empire Strikes Back and Episode VI: Return of the Jedi. It has a new creative team, and although I don't consider it to be as good as her first series, it's still an enjoyable read. Early on it tries to focus on an entirely new supporting cast, who took longer to capture my interest than the characters in the previous series.
  
      However, my appreciation for this series grew over time and I really enjoyed the second half of this series starting with the Spark Eternal storyline. Unfortunately, this omnibus only collects the first 25 issues of a 40 issue series so it doesn't include many of the best issues. Hopefully Marvel doesn't keep the fans waiting too long for the last 15 issues to be collected.`,
    'amazon': 'https://amzn.to/3KgN2L0',
    'release_date': '2023-08-29',
    'included_issues': [
      609,
      610,
      611,
      612,
      613,
      624,
      625,
      626,
      627,
      637,
      643,
      647,
      649,
      652,
      659,
      666,
      667,
      668,
      669,
      670,
      671,
      884,
      885,
      886,
      887,
    ],
    'img': 800005,
  },
  {
    'title': 'Doctor Aphra Omnibus Vol. 1',
    'summary': `This omnibus collects the entirety of Doctor Aphra's first comic series. Likely the most popular Star Wars character created by Marvel, Aphra's series explores unseen corners of the Star Wars galaxy through her role as an archeologist. In her adventures she leaves a trail of scams, double crosses, and broken hearts as she struggles to overcome her rogue nature.
      
      One of the highlights of this collection is the characters, both new and familiar, and their complex relationships. With multiple love interests, a reunion with a family member, and no shortage of former associates she's betrayed, there are so many entertaining character moments that make it hard to put this book down.
      
      The artwork is also quite impressive in this collection, with a style that fits the story perfectly and is pleasant to look at. However, there were some moments with no captions and the artwork is left to convey the action, but I couldn't tell what was happening and struggled to follow along.`,
    'amazon': 'https://amzn.to/3yzLrgI',
    'release_date': '2023-01-31',
    'included_issues': [
      484,
      485,
      486,
      487,
      488,
      489,
      492,
      494,
      496,
      497,
      498,
      499,
      500,
      501,
      507,
      508,
      509,
      510,
      511,
      512,
      513,
      514,
      515,
      516,
      517,
      518,
      519,
      520,
      521,
      522,
      523,
      524,
      525,
      557,
      558,
      559,
      560,
      561,
      562,
      563,
      564,
      565,
      566,
      432,
      433,
      445,
      453,
      461,
      468,
      472,
      490,
      491,
      493,
      1032,
    ],
    'img': 800004,
  },
  {
    'title': 'Darth Vader by Charles Soule Omnibus',
    'summary': `
    This is my absolute favourite Star Wars omnibus and I don't see it being outdone any time soon. It is packed with fantastic stories from cover-to-cover with four thrilling story arcs. 

    This series starts immediately after the events of Episode III: Revenge of the Sith and shows the beginning of Darth Vader's service to the Empire. It features so many essential Vader stories, like building his red lightsaber, meeting the Inquisitors, returning to Mon Cala, and even constructing his castle on Mustafar.

    Every issue in the 25 issue series has the same writer and artist, which gives the whole collection a very consistent experience. There is one annual included by a different creative team that is mildly enjoyable, but also somewhat frustrating in that it ends on a cliffhanger that is never followed up on. This is my top recommendation for a Star Wars omnibus, this series is everything a Star Wars fan could ask for in a Darth Vader comic. 
    `,
    'amazon': 'https://amzn.to/3ywStCZ',
    'release_date': '2022-01-26',
    'included_issues': [
      234,
      235,
      236,
      237,
      238,
      239,
      240,
      241,
      242,
      243,
      244,
      245,
      261,
      262,
      263,
      264,
      265,
      268,
      274,
      275,
      276,
      277,
      278,
      279,
      280,
      281,
    ],
    'img': 800000,
  },
  {
    'title': 'Star Wars by Jason Aaron Omnibus',
    'summary': `This series follows the adventures of Han, Luke, Leia, and Chewbacca shortly after Episode IV: A New Hope. These are fun, action-heavy adventures that seek to capture the feeling of the original trilogy. Though it's quite successful in that regard, it would have been nice to see more influence from the prequel trilogy. 

    The first two story arcs and the Vader Down crossover are incredible and must read for any Star Wars fans. The Journals of Old Ben Kenobi are also amazing flashbacks that should appeal to any fans of the old Jedi Master. The next two arcs are also great stories, particularly noteable for introducing the elite group of Stormtroopers known as SCAR Squadron. 
    
    Unfortunately the last few story arcs are not as great, with artwork that's hard to look at and stories that feel very inconsequntial. Despite having some rough patches, this is a great read and an excellent way to jump into Star Wars comics!
    `,
    'amazon': 'https://amzn.to/44SXGAQ',
    'release_date': '2022-05-03',
    'included_issues': [
      425,
      426,
      427,
      429,
      434,
      436,
      438,
      439,
      440,
      441,
      442,
      443,
      451,
      452,
      453,
      454,
      455,
      456,
      430,
      457,
      458,
      459,
      460,
      461,
      462,
      473,
      474,
      475,
      476,
      477,
      478,
      479,
      480,
      481,
      482,
      483,
      490,
      491,
      492,
      493,
      494,
      495,
      502,
      503,
      504,
      506,
      505,
    ],
    'img': 800001,
  },
  {
    'title': 'Darth Vader by Gillen & Larroca Omnibus',
    'summary': `This is a fantastic series that shows Vader's rise to power within the Empire between Episode IV: A New Hope and Episode V: The Empire Strikes Back. This series is famous for introducing the character Doctor Aphra, who quickly became a fan favourite. 
    
    Here we see a more strategic Vader, who uses various schemes to pursue his own agenda while also serving the Empire. The core plot of this series revolves around a doctor specialing in cybernetics who creates a group of enhanced soldiers he intends as Vader's replacements. Vader faces off against these agents throughout the series, as he seeks to eliminate his competition without leaving any evidence of murdering an Imperial resource.
    
    Although I critice Larroca's artwork in other omnibus on this page, his work is much better in this collection. However, the writing is certainly the selling point in this collection, with Gillen establishing himself as a top Star Wars writer and setting him up to return on the main Star Wars line shortly after.`,
    'release_date': '2022-04-27',
    'included_issues': [
      428,
      431,
      432,
      433,
      435,
      437,
      444,
      445,
      446,
      447,
      448,
      449,
      451,
      452,
      453,
      454,
      455,
      456,
      463,
      464,
      465,
      466,
      467,
      468,
      469,
      470,
      471,
      472,
    ],
    'img': 800003,
  },
]

class OmnibusGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = this.props.data;

    return (
      <div className="blog-inner">
        <div className="blog-header">
          <h1>Guide to Marvel's Star Wars Canon Omnibus</h1>
        </div>
        {
          OMNIBUS_LIST.map(x => {
            if (!data)
            {
              return x;
            }
            const collection_data = this.props.data?.filter(media => x.included_issues.includes(media.media_id));
            const issue_list = agg_issues(collection_data);
            x['body'] = issue_list + '\n' + x['summary'];

            // create a single collection that aggregates all the contents
            const media_data = {
              release_date: x['release_date'],
              avg_rating: calc_avg(collection_data, 'avg_rating'),
              rating_count: calc_sum(collection_data, 'rating_count'),
              user_rating: calc_avg(collection_data, 'user_rating'),
              mediaIdList: collection_data.map(x => x.media_id),
              format: 'C',
            };
            x['data'] = media_data;

            return x
          }).map(x => {
            return (
              <BlogEntry
                blogData={x}
                data={x.data}
                key={`recommended-${x.title}`}
                loggedIn={this.props.loggedIn}
                addRating={this.props.addRating}
              ></BlogEntry>
            )
          })
        }
      </div>
    );
  }
}

export default OmnibusGuide;
