import React from 'react';
import BaseModal from './BaseModal';
import '../App.css';

class PasswordResetModal extends React.Component {
  render() {
    const { isOpen, onRequestClose, onSubmit, signupErr, requestReset } = this.props;

    return (
      <BaseModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title="Reset Password"
        content={
          <div className="form">
            <div className="input-container">
              <label>Username</label>
              <input type="text" id="reset-uname" />
            </div>
            <button onClick={() => requestReset()}>Request Password Reset</button>
            <div className="input-container">
              <label>Token From Reset Email</label>
              <input type="text" id="reset-token" />
            </div>
            <div className="input-container">
              <label>New Password</label>
              <input type="password" id="reset-pass" />
            </div>
            <div className="input-container">
              <label>Confirm New Password</label>
              <input type="password" id="reset-pass-confirm" />
            </div>
            <p style={{ 'color': 'red', 'width': '300px' }}>
              {signupErr}
            </p>
            <div className="button-container">
              <button onClick={onSubmit}>Reset Password</button>
            </div>
          </div>
        }
      >
      </BaseModal>
    );
  }
}

export default PasswordResetModal;
