// export const URL = 'http://localhost/api'
export const URL = 'https://swtimeline.net/api'

export const RATING_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const MEDIA_TYPE_MAP = {
  'F': 'Films',
  'T': 'Shows',
  'N': 'Novels',
  'C': 'Comics',
  'V': 'Video Games',
};

export const ERA_MAP = [
  'Dawn of the Jedi',
  'The Old Republic',
  'The High Republic',
  'Fall of the Jedi',
  'Reign of the Empire',
  'Age of Rebellion',
  'The New Republic',
  'Rise of the First Order',
  'New Jedi Order',
]

export function openUrl(url) {
  window.open(url, "_blank")
}

export const ALL_FILTERS = [
  {
    'name': 'userRatingFilter',
    'desc': 'Show media you have rated',
    'func': (x) => { return !x.user_rating },
  },
  {
    'name': 'userNoRatingFilter',
    'desc': 'Show media you have not rated',
    'func': (x) => { return x.user_rating },
  },
  {
    'name': 'collectionFilter',
    'desc': 'Group comics by paperback',
    'func': (x) => { return x.collection_title && x.format === 'C' && !x.collection_order },
  },
  {
    'name': 'seasonFilter',
    'desc': 'Group TV shows by season',
    'func': (x) => { return x.collection_title && x.format === 'T' && !x.collection_order },
  },
  {
    'name': 'showImportant',
    'desc': 'Hide filler',
    'func': (x) => { return x.important < 1 },
  },
  {
    'name': 'hideComics',
    'desc': 'Hide comics',
    'func': (x) => { return x.format === 'C' },
  },
  {
    'name': 'hideNovels',
    'desc': 'Hide novels',
    'func': (x) => { return x.format === 'N' },
  },
  {
    'name': 'hideTV',
    'desc': 'Hide TV shows',
    'func': (x) => { return x.format === 'T' },
  },
  {
    'name': 'hideShorts',
    'desc': 'Hide shorts (Forces of Destiny)',
    'func': (x) => { return x.important < 0 },
  },
];

export const ACHIEVEMENTS = [
  {
    'name': 'Light and Life',
    'description': 'Rate {count} High Republic novels',
    'icon': ['medal-hr-silver', 'medal-hr'],
    'func': (x) => { return x.title.includes('High Republic:') && x.format === 'N' },
    'count': [5, 12],
  },
  {
    'name': 'For The Republic',
    'description': 'Rate {count} episodes of The Clone Wars',
    'icon': ['medal-332nd-silver', 'medal-332nd'],
    'func': (x) => { return x.title.includes('The Clone Wars:') },
    'count': [40, 100],
  },
  {
    'name': 'Clone Force 99',
    'description': 'Rate {count} episodes of The Bad Batch',
    'icon': ['medal-badbatch-silver', 'medal-badbatch'],
    'func': (x) => { return x.title.includes('The Bad Batch:') },
    'count': [16, 40],
  },
  {
    'name': 'Son of Dathomir',
    'description': "Rate {count} of Maul's key appearances, including his comics, Episode I: The Phantom Menace, The Clone Wars, and Rebels",
    'icon': ['medal-maul-silver', 'medal-maul'],
    'func': (x) => {
      return x.title.includes('Darth Maul')
        || [60, 174, 175, 191, 192, 193, 194, 228, 230, 231, 232, 363, 367, 375, 383].includes(x.media_id)
    },
    'count': [8, 18],
  },
  {
    'name': 'Stardust',
    'description': 'Rate Catalyst and Rogue One',
    'icon': ['medal-ds'],
    'func': (x) => { return [267, 399].includes(x.media_id) },
    'count': [2],
  },
  {
    'name': 'Dark Lord',
    'description': 'Rate {count} Darth Vader comics',
    'icon': ['medal-vader-silver', 'medal-vader'],
    'func': (x) => { return (x.title.includes('Darth Vader') || x.title.includes('Vader:')) && x.format === 'C' },
    'count': [25, 50],
  },
  {
    'name': 'Phoenix Squad',
    'description': 'Rate {count} episodes of Rebels',
    'icon': ['medal-rebels-silver', 'medal-rebels'],
    'func': (x) => { return x.title.includes('Rebels:') },
    'count': [25, 50],
  },
  {
    'name': 'This Is The Way',
    'description': 'Rate {count} episodes of The Mandalorian',
    'icon': ['medal-mando-silver', 'medal-mando'],
    'func': (x) => { return x.title.includes('The Mandalorian:') },
    'count': [8, 16],
  },
]

export function get_earned_achievements(data) {
  // returns an array of objects containing data on any earned achievements
  const earnedAchievements = []
  const user_ratings = data.filter(x => x.user_rating);

  ACHIEVEMENTS.forEach(a => {
    const achievement = { name: a.name }
    achievement.progress = calc_count(user_ratings.filter(x => a.func(x)), 'user_rating');
    a.count.forEach((count, index) => {
      if (achievement.progress >= count) {
        achievement.icon = a.icon[index];
        achievement.count = count;
        achievement.description = a.description;
      }
      else if (!achievement.nextTier) {
        achievement.nextTier = count;
      }
    })
    if (achievement.icon) {
      earnedAchievements.push(achievement);
    }
  });

  return earnedAchievements;
}

export function filter_media(rawData, filters) {
  let data = rawData;

  // exclude any media that doesn't have a release date
  data = data.filter(x => x.release_date);

  ALL_FILTERS.forEach(filter => {
    // apply any filters needed for aggregation
    if (['collectionFilter', 'seasonFilter'].includes(filter.name)) {
      data = data.filter(x => !(filters.includes(filter.name) && filter.func(x)));
    }
  })

  // aggregate the data
  data = data.map(row => {
    if (row.collection_title
      && ((filters.includes('collectionFilter') && row.format === 'C')
        || (filters.includes('seasonFilter') && row.format === 'T'))) {
      const collection_data = rawData.filter(x =>
        row.collection_title === x?.collection_title);
      // modify the row to represent the whole collection
      row = Object.assign({}, row);
      row.avg_rating = calc_avg(collection_data, 'avg_rating');
      row.rating_count = calc_sum(collection_data, 'rating_count');
      row.user_rating = calc_avg(collection_data, 'user_rating');
      row.user_rating_count = calc_count(collection_data, 'user_rating');
      row.summary = agg_summary(collection_data);
      row.title = row.collection_title;
      row.mediaIdList = collection_data.map(x => x.media_id);
      if (row.format === 'C') {
        row.collected_issues = agg_issues(collection_data);
      }
      delete row.subtitle;
    } else {
      row.user_rating_count = row.user_rating ? 1 : 0;
      row.mediaIdList = [row.media_id];
    }
    return row;
  });

  ALL_FILTERS.forEach(filter => {
    // apply any additional filters to the aggregated data
    if (!['collectionFilter', 'seasonFilter'].includes(filter.name)) {
      data = data.filter(x => !(filters.includes(filter.name) && filter.func(x)));
    }
  })

  return data;
}

export function agg_summary(data) {
  let summary = '';
  for (const x of data) {
    // roughly 1500 characters is the most that can be displayed in the panel
    if (summary.length < 1200 && x?.summary) {
      summary += ' ';
      summary += x?.summary || '';
    }
  }
  return summary;
}

export function calc_avg(data, field) {
  // calculate the average value of field
  // across each element in data
  if (data.filter(x => x[field]).length === 0) {
    return 0;
  }
  if (data.length === 1) {
    return data[field];
  }

  let sum = 0;
  let count = 0;

  data.forEach(x => {
    if (x[field]) {
      sum += parseFloat(x[field]);
      count++;
    }
  })

  return sum / count;
}

export function calc_sum(data, field) {
  let sum = 0;

  data?.forEach(x => {
    if (x[field]) {
      sum += parseFloat(x[field]);
    }
  })

  return sum;
}

export function calc_count(data, field) {
  let count = 0;

  data?.forEach(x => {
    if (x[field]) {
      count++;
    }
  })

  return count;
}

export function agg_issues(data) {
  // this function takes a list of comic issue titles and formats it neatly summarizing the titles
  // for example, COLLECTING: Darth Maul (2017) #1-5
  const issue_summary = {};
  let collection_text = 'COLLECTING: ';

  // create a dictionary mapping comic titles to an array of the issue numbers from that series
  data?.forEach(x => {
    const split_title = x.title.split(" ");
    const issue = split_title.pop();
    const title = split_title.join(' ');

    if (issue_summary[title]) {
      issue_summary[title].push(issue);
    } else {
      issue_summary[title] = [issue];
    }
  });

  // turn the mapping of series titles and issue numbers into readable text
  Object.keys(issue_summary).forEach((title, i) => {
    if (i > 0) {
      collection_text += ", ";
    }

    if (issue_summary[title].length > 1) {
      collection_text += `${title} #`;
      issue_summary[title].forEach((issue_num, index) => {
        // add the first number in a range
        if (index === 0 || issue_num - issue_summary[title][index - 1] > 1) {
          if (index > 0) {
            collection_text += ', ';
          }
          collection_text += `${issue_num}`;
        }
        // add the last number in a range
        else if (index === issue_summary[title].length - 1 || issue_summary[title][index + 1] - issue_num > 1) {
          collection_text += `-${issue_num}`;
        }
      })
    } else {
      collection_text += `${title} #${issue_summary[title][0]}`
    }
  });

  return collection_text;
}

export const eventGuideData = {
  'dark_droids_guide': {
    "img": 900135,
    "title": "Dark Droids",
    "body": "When the droid known as the Scourge joins with the Spark Eternal, it believes it finally has the power to take over the galaxy. It begins by possessing droids but seeks to grow it's influence to control cyborgs and fully organic beings as well. The galaxy is thrown into chaos as they are betrayed by droids all around them.",
  },
  'war_of_the_bounty_hunters_guide': {
    "img": 900088,
    "title": "War of the Bounty Hunters",
    "body": "In the aftermath of Episode V: The Empire Strikes Back, Han Solo is in Boba Fett's possession. However, Luke, Leia, and Chewbacca aren't the only people after him! Darth Vader, Doctor Aphra, Valance, and a wide range of Bounty Hunters are chasing him for their own reasons. This event is filled with brawls and duels as all the interested parties attempt to take Han for themselves, with Boba Fett at the center of the action.",
  }
}

export function convertDataToUrl(data) {
  // for comics we use the paperback's title
  const title = data?.format === 'C' ? data?.collection_title || data?.title : data?.title
  if (!title) {
    return "";
  }
  else {
    return convertTitleToUrl(title);
  }
}

export function convertTitleToUrl(title) {
  return title?.toLowerCase().replaceAll(' ', '_').replaceAll('"', '').replaceAll("'", '').replaceAll("&", '');
}
