import React from 'react';
import BaseModal from './BaseModal';
import '../App.css';

class LoginModal extends React.Component {
  render() {
    const { isOpen, onRequestClose, handleLogin, signupErr, toggleShowPasswordReset } = this.props;

    return (
      <BaseModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title="Login"
        content={
          <div className="form">
            <div className="input-container">
              <label>Username </label>
              <input type="text" id="uname" />
            </div>
            <div className="input-container">
              <label>Password </label>
              <input type="password" id="pass" />
            </div>
            <p style={{ 'color': 'red', 'width': '300px' }}>
              {signupErr}
            </p>
            <div className="header-box">
              <button onClick={() => handleLogin('login')}>Login</button>
              <button onClick={toggleShowPasswordReset}>Forgot Password</button>
            </div>
          </div>
        }
      >
      </BaseModal>
    );
  }
}

export default LoginModal;
