import React from 'react';
import './App.css';
import { openUrl } from './Common';
import Timeline from './Timeline';


class EventGuide extends React.Component {
  componentDidMount() {
    const structuredDataText = {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": `${this.props.guideData.title} Reading Order`,
      "image": [
        `https://swtimeline.net/img/${this.props.guideData.img}.png`
      ],
      "datePublished": "2023-01-10T08:00:00+08:00",
      "dateModified": "2023-01-10T09:20:00+08:00",
      "author": [{
        "@type": "Person",
        "name": "Jake Loftus",
        "url": "https://swtimeline.net"
      }],
    }
    const script = document.getElementById('custom-ld-json');
    script.textContent = JSON.stringify(structuredDataText);
  }

  render() {
    const guideData = this.props.guideData;
    const data = this.props.data.filter(x => x?.collection_title?.includes(guideData.title));
    const progress = Math.floor(data.filter(x => x?.user_rating).length * 100.0 / data.length);

    return (
      <div>
        <div className="App-timeline-rows">
          <div className="blog-inner">
            <div className="blog-header">
              <h1>{guideData.title} Reading Order</h1>
            </div>
            <div className="blogEntry infopanel-inner">
              <div className='blog-body'>
                <div className="image">
                  <img
                    loading="lazy"
                    src={`/img/${guideData.img}.png`}
                    style={{ height: '100%' }}
                    alt={`Cover art of Dark Droids 1`}
                  />
                  {
                    guideData?.amazon
                      ? <button
                        className="blog-button"
                        onClick={() => openUrl(guideData.amazon)}
                      >View on Amazon</button>
                      : <div />
                  }
                </div>
                <div
                  className="blog-text"
                >
                  {
                    guideData.body?.split('\n')?.map((x, index) => {
                      return (
                        <p
                          key={`blog-text-${index}`}
                        >
                          {x}
                        </p>
                      )
                    })
                  }
                </div>
              </div>
              {
                this.props.loggedIn
                  ? <div>
                    <div className="subtitle">Your progress:</div>
                    <div className="progress-bar-outer">
                      <div className="progress-bar-inner" id="dark-droids-progress" style={{ width: `${progress}%` }}>
                        {`${progress}%`}
                      </div>
                    </div>
                  </div>
                  : <div className="subtitle">Log in to track your progress!</div>
              }
            </div>

          </div>
        </div>
        <Timeline
          data={data}
          loggedIn={this.props.loggedIn}
          addRating={this.props.addRating}
          filters={[]}
          showSidePanel={false}
        />
      </div>
    );
  }
}

export default EventGuide;
