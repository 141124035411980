import React from 'react';
import { mdiCircle , mdiCheckCircle } from '@mdi/js';
import Icon from '@mdi/react';

const MEDIA_TYPE_MAP = {
  'F': 'film',
  'C': 'comic',
  'N': 'novel',
  'V': 'videogame',
  'T': 'tv',
};

class TimelineElement extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const data = this.props.data;
    const aggregate = this.props.aggregate;
    const selected = this.props.selected ? 'selected' : '';

    return (
      <div
        className={`timelineElementWrapper ${MEDIA_TYPE_MAP[data.format]} ${selected}`}
        onClick={this.props.onClick}
      >
        <Icon path={data?.user_rating ? mdiCheckCircle : mdiCircle} size="1.3em" className={`timelineCircle ${MEDIA_TYPE_MAP[data.format]}`} />
        <div className={`timelineElement ${MEDIA_TYPE_MAP[data.format]}`}>
          {aggregate ? (data.collection_title || data.title) : data.title}
        </div>
      </div>
    );
  }
}

export default TimelineElement;
