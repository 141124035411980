import React from 'react';
import './App.css';
import { MEDIA_TYPE_MAP, URL, calc_sum, filter_media } from './Common';
import ListDisplayPanel from './ListDisplayPanel';
import { NavLink } from "react-router-dom";

const LEADERBOARD_SLOTS = 20;

class Leaderboard extends React.Component {
  loadLeaderboardData() {
    fetch(`${URL}/leaderboard`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
    })
      .then(res => res.text())
      .then(res => {
        this.setState({ leaderboard: JSON.parse(res) });
      })
      .catch(err => err);
  }

  componentDidMount() {
    this.loadLeaderboardData();
  }

  render() {
    // avoid random content with a single 10 star review taking the top leaderboard spot
    const data = filter_media(this.props.data, ['collectionFilter', 'seasonFilter']).filter(x => x.rating_count > 2);
    const leaderboard = this.state?.leaderboard?.sort((a, b) => b.rating_count - a.rating_count).slice(0, LEADERBOARD_SLOTS);

    return (
      <div className="profile-wrapper">
        <div className="profile">
          <div className="profile-panel">
            <div className="blog-body">
              <div style={{ 'width': '100%' }}>
                <div className="subtitle">{`Top ${LEADERBOARD_SLOTS} Users by Ratings Submitted`}</div>
                <table style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', borderCollapse: 'collapse' }}>
                  <tbody>
                    {
                      leaderboard?.map(x => {
                        return (
                          <tr key={`leaderboard-${x.username}`}>
                            <td>
                              <NavLink
                                className='link-text'
                                to={`/profile/${x.username}`}
                                style={{ display: 'block' }}
                              >
                                {`${x.username}`}
                              </NavLink>
                            </td>
                            <td style={{ 'paddingLeft': '60px', 'textAlign': 'right' }}>
                              {`${parseInt(x.rating_count)}`}
                            </td>
                          </tr>
                        )
                      })
                    }
                    {
                      !this.props.username || leaderboard?.find(x => x.username === this.props.username)
                        ? <tr />
                        : <tr>
                          <td style={{padding: '0px 20px'}}>...</td>
                        </tr>
                    }
                    {
                      !this.props.username || leaderboard?.find(x => x.username === this.props.username)
                        ? <tr />
                        : <tr key={`leaderboard-${this.props.username}`}>
                          <td>
                            <NavLink
                              className='link-text'
                              to={`/profile/${this.props.username}`}
                              style={{ display: 'block' }}
                            >
                              {`${this.props.username}`}
                            </NavLink>
                          </td>
                          <td style={{ 'paddingLeft': '60px', 'textAlign': 'right' }}>
                            {`${calc_sum(this.props.data, 'user_rating_count') || 0}`}
                          </td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {
            Object.keys(MEDIA_TYPE_MAP).map(media_type => {
              return (
                <ListDisplayPanel
                  title={`Highest Rated ${MEDIA_TYPE_MAP[media_type]}`}
                  data={data.filter(x => x.format === media_type && x.avg_rating)}
                  valueField='avg_rating'
                  key={`list-panel-${media_type}`}
                >
                </ListDisplayPanel>
              )
            })
          }
        </div>
      </div>
    );
  }
}

export default Leaderboard;
