import React from 'react';
import './App.css';
import { ACHIEVEMENTS } from './Common';

class Methodology extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const structuredDataText = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [{
        "@type": "Question",
        "name": "What is Star Wars canon?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `Canon encompasses all media that the Lucasfilm Story Group considers to be a "true" story within the Star Wars universe. Anything published before Disney's acquisition of Star Wars, excluding Episodes I-VI, The Clone Wars film, and The Clone Wars TV series, is non-canon. There are a few unique cases:
            Star Wars: The Old Republic continues to release new content after the Disney acquisition but is non-canon. 
            The From a Certain Point of View books were published after the acquisition but are non-canon. 
            Darth Maul: Son of Dathomir was published by Dark Horse after the acquisition but before comic rights were moved to Marvel and is canon. 
            `
        }
      },
      {
        "@type": "Question",
        "name": "How is this Star Wars timeline different from other sites?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": `The timelines on this site may not be perfectly aligned with what you see in other sources, but that is intentional. This site is designed to be more focused on creating a pleasant viewing order than a perfect chronological timeline. 95% of the time there would be no difference in placement based on these two objectives, however here I outline some of the deviations:
            Anthology series such as the Star Wars Adventures comics and Star Wars: Forces of Destiny shorts are not included in the timeline. Including so many 12 page comics or 3 minute videos in the timeline will significantly clutter it without any meaningful additions to canon.
            In contrast with other timelines, I typically place media by when it ends rather than when it begins. For example, some sites place the novel Lost Stars before Episode IV: A New Hope, but I've placed it after Episode VI: Return of the Jedi since the book covers the entire original trilogy.
            There are quite a few stories with a framing story that retells the events of the past, such as the Kanan comic series. The flashbacks takes place throughout the Clone Wars while the framing story and all of issues 6 and 12 take place during Rebels season one. The series was clearly designed to be read in release order rather than chronological order, so I've placed all 12 issues during Rebels season one.
            `
        }
      }]
    }
    const script = document.getElementById('custom-ld-json');
    script.textContent = JSON.stringify(structuredDataText);
  }

  render() {
    return (
      <div className="blog-inner" style={{ 'padding': '20px' }}>
        <div className="blog-header">
          <h1>What is Star Wars canon?</h1>
        </div>
        <p>
          Canon encompasses all media that the Lucasfilm Story Group considers to be a "true" story within the Star Wars universe. Anything published before Disney's acquisition of Star Wars, excluding Episodes I-VI, The Clone Wars film, and The Clone Wars TV series, is non-canon. There are a few unique cases:
        </p>
        <ul>
          <li>
            Star Wars: The Old Republic continues to release new content after the Disney acquisition but is non-canon.
          </li>
          <li>
            The From a Certain Point of View books were published after the acquisition but are non-canon.
          </li>
          <li>
            Darth Maul: Son of Dathomir was published by Dark Horse after the acquisition but before comic rights were moved to Marvel and is canon.
          </li>
        </ul>
        <div className="blog-header">
          <h1>How is this Star Wars timeline different from other sites?</h1>
        </div>
        <p>
          The timelines on this site may not be perfectly aligned with what you see in other sources, but that is intentional. This site is designed to be more focused on creating a pleasant viewing order than a perfect chronological timeline. 95% of the time there would be no difference in placement based on these two objectives, however here I outline some of the deviations:
        </p>
        <ul>
          <li>
            Anthology series such as the Star Wars Adventures comics and Star Wars: Forces of Destiny shorts are not included in the timeline. Including so many 12 page comics or 3 minute videos in the timeline will significantly clutter it without any meaningful additions to canon.
          </li>
          <li>
            In contrast with other timelines, I typically place media by when it ends rather than when it begins. For example, some sites place the novel Lost Stars before Episode IV: A New Hope, but I've placed it after Episode VI: Return of the Jedi since the book covers the entire original trilogy.
          </li>
          <li>
            There are quite a few stories with a framing story that retells the events of the past, such as the Kanan comic series. The flashbacks takes place throughout the Clone Wars while the framing story and all of issues 6 and 12 take place during Rebels season one. The series was clearly designed to be read in release order rather than chronological order, so I've placed all 12 issues during Rebels season one.
          </li>
        </ul>
        <div className="blog-header">
          <h1>How does this site's rating scale work?</h1>
        </div>
        <p>The rating scale used on this site has a 1-10 scale, with 10 being the best. It is designed to be similar to other rating sites, such as IMDB. Thus, the overall average rating for all media should be around a 7.5, with a full rating guide below:</p>
        <ul>
          <li>10 - Flawless, stands out as some of your favourite Star Wars media</li>
          <li>9 - Amazing, it may not be perfect but it was exceptionally enjoyable</li>
          <li>8 - Great, you quite enjoyed it but weren't totally wow'd by it</li>
          <li>7 - Very Good, you enjoyed it but wouldn't go out of your way to watch it again anytime soon</li>
          <li>6 - Fine, you didn't hate it but it had problems or you were bored</li>
          <li>5 - Meh, you were pretty bored or it had some big problems</li>
          <li>4 - Poor, it was really, really bad</li>
          <li>3 - Trash, it was not only really, really bad but it also somewhat damaged Star Wars in your mind</li>
          <li>2 - Poodoo, absolutely awful and you can't stop thinking about how bad it is</li>
          <li>1 - Bantha poodoo, you are offended by it's very existence and it turns you off Star Wars</li>
        </ul>
        <div className="blog-header">
          <h1>What achievements can I earn?</h1>
        </div>
        <ul>
          {
            ACHIEVEMENTS.map(a => {
              return (
                a.count.map((count, i) => {
                  return (
                    <li key={`${a.name}-${count}`}>
                      <strong>
                        {`${a.name} ${a.count.length === 1 || i === 1 ? '(Gold)' : '(Silver)'}: `}
                      </strong>
                      {`${a.description.replace('{count}', count)}`}
                    </li>
                  )
                })
              )
            })
          }
        </ul>
        <p>If you have questions or feedback feel free to send me an email at jakeloftuscomics@gmail.com</p>
      </div>
    );
  }
}

export default Methodology;
