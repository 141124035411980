import React from 'react';
import { openUrl, eventGuideData, convertDataToUrl, convertTitleToUrl, URL, filter_media } from './Common';
import RatingsSelect from './RatingsSelect';
import { NavLink } from "react-router-dom";
import EraIcon from './EraIcon';
import { mdiThumbUp } from '@mdi/js';
import Icon from '@mdi/react';


const COMMENT_LIMIT = 2000;

class MediaPage extends React.Component {
  constructor(props) {
    super(props);
    this.submitComment = this.submitComment.bind(this);
  }

  getDataIndex(rawData) {
    const view = window.location.href.split('/').at(-1);

    if (!rawData) {
      return -1;
    }

    let dataIndex = rawData.findIndex(x => convertDataToUrl(x) === view.toLowerCase());
    if (dataIndex < 0) {
      // if the click on the season of a show, return the first episode
      dataIndex = rawData.findIndex(x => x.format === 'T' && convertTitleToUrl(x.collection_title) === view.toLowerCase());
      if (dataIndex < 0) {
        // check if the show was referencing by episode number instead of by title
        dataIndex = rawData.findIndex(x => x.format === 'T' && convertTitleToUrl(`${x.title.split(':')?.[0]}: ${x.subtitle}`) === view.toLowerCase());
      }
    }

    if (dataIndex < 0) {
      return -1;
    }
    else {
      return dataIndex;
    }
  }

  componentDidMount() {
    this.setState({ data: null });
  }

  componentDidUpdate() {
    const rawData = filter_media(this.props.data.sort((a, b) => a?.sort_order - b?.sort_order), ['collectionFilter']);
    const dataIndex = this.getDataIndex(rawData)
    if (dataIndex < 0
      || (
        this.state?.data
        && rawData[dataIndex].media_id === this.state.data.media_id
        && rawData[dataIndex].avg_rating === this.state.data.avg_rating
      )
    ) {
      return;
    }

    const oldMediaId = this.state?.data?.media_id
    const data = rawData[dataIndex];
    this.setState({ data });

    if (oldMediaId !== data.media_id) {
      this.loadComments(data.media_id);
    }
  }

  loadComments(mediaId) {
    if (!mediaId) {
      return;
    }

    fetch(`${URL}/get_comments`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        mediaId: mediaId,
      })
    })
      .then(res => res.text())
      .then(res => {
        const comments = JSON.parse(res);
        this.setState({
          comments: comments,
        });
      })
      .catch(err => err);
  }

  submitComment() {
    const mediaId = this.state?.data?.media_id;
    if (!mediaId) {
      return;
    }

    const comment = document.getElementById(`comment-box-${this.state?.data?.media_id}`)?.value;
    if (!comment) {
      return;
    }

    if (comment.length >= COMMENT_LIMIT) {
      this.setState({ commentErr: `Comment length of ${comment.length} exceeds limit of ${COMMENT_LIMIT} characters!` })
      return;
    }

    this.setState({
      commentErr: null,
    });

    fetch(`${URL}/add_comment`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        mediaId: mediaId,
        comment: comment,
      })
    })
      .then(res => res.text())
      .then(res => {
        const data = JSON.parse(res);
        if (data?.error) {
          this.setState({
            commentErr: data?.error,
          });
        }
        else if (data?.comment_id) {
          const newComments = this.state.comments || [];
          newComments.push(Object.assign(data, { 'username': this.props.username }));
          document.getElementById(`comment-box-${this.state?.data?.media_id}`).value = "";
          this.setState({ comments: newComments });
        }
      })
      .catch(err => err);
  }

  likeComment(commentId, liked) {
    if (!commentId) {
      return;
    }

    fetch(`${URL}/like_comment`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: "include",
      body: JSON.stringify({
        commentId: commentId,
        liked: liked,
      })
    })
      .then(res => res.text())
      .then(res => {
        const data = JSON.parse(res);
        if (data?.comment_id === 0 || data?.comment_id > 0) {
          const newComments = this.state.comments.map(x => {
            // alter the comment to either add or remove the current user's like
            if (x.comment_id === data.comment_id) {
              if (data.liked) {
                x.like_users = x.like_users.filter(u => u);
                x.like_users.push(this.props.username)
              }
              else {
                x.like_users = x.like_users.filter(u => u && u !== this.props.username)
              }
            }
            return x;
          })

          this.setState({ comments: newComments });
        }
      })
      .catch(err => err);
  }

  render() {
    const view = window.location.href.split('/').at(-1);

    if (!this.props.data || !view) {
      return null;
    }

    const rawData = filter_media(this.props.data.sort((a, b) => a?.sort_order - b?.sort_order), ['collectionFilter']);
    const dataIndex = this.getDataIndex(rawData);

    const data = rawData[dataIndex];
    const prevData = rawData[dataIndex - 1];
    const nextData = rawData[dataIndex + 1];

    let eventGuidePath;
    Object.entries(eventGuideData).map(([path, guideData]) => {
      if (this.props.data?.collection_title?.includes(guideData.title)) {
        eventGuidePath = path;
      }
    });

    return (
      <div className="blog-inner">
        <div className="infopanel-inner header-box">
          <div>
            <div className="title">{data?.title}</div>
            <div>{data?.subtitle}</div>
            <RatingsSelect
              data={data}
              loggedIn={this.props.loggedIn}
              addRating={this.props.addRating}
            >
            </RatingsSelect>
            <div>
              {
                data?.release_date
                  ? `Release date: ${new Date(data?.release_date).toISOString().slice(0, 10)}`
                  : 'Not yet released'
              }
            </div>
          </div>
          <EraIcon data={data} />
        </div>
        <div className="infopanel-inner blog-body">
          <div className="image">
            <img
              src={`/img/${data?.img || 'blank'}.png`}
              style={{ height: '100%' }}
              alt={`Cover art of ${data?.title}`}
            />
            {
              data?.amazon
                ? <button
                  onClick={() => openUrl(data.amazon)}
                >View on Amazon</button>
                : <div />
            }
          </div>
          <div>
            <div className="title">Plot Summary</div>
            <p>{data?.summary || 'Plot summary coming soon...'}</p>
            <p>{data?.collected_issues}</p>
            {
              data?.notes
                ? <div><div className="title">Timeline Placement Notes</div><p>{data?.notes}</p></div>
                : <div />
            }
            {
              eventGuidePath
                ? <NavLink
                  to={`/${eventGuidePath}`}
                >
                  <button>View Event Guide</button>
                </NavLink>
                : <div />
            }
          </div>
        </div>
        <div className="infopanel-inner blog-body header-box">
          {
            prevData
              ?
              <NavLink
                className="content-suggestion"
                to={`/content/${convertDataToUrl(prevData)}`}
              >
                <div style={{ display: 'flex', width: '300px', padding: '10px', textAlign: 'start', justifyContent: 'start' }}>
                  <img
                    src={`/img/${prevData?.img || 'blank'}.png`}
                    style={{ height: '100px', margin: '10px' }}
                    alt={`Cover art of ${data?.title}`}
                  />
                  <div>
                    <div className="title">Previous in timeline:</div>
                    <p>{`${prevData.title}`}</p>
                  </div>
                </div>

              </NavLink>
              : <p />
          }
          {
            nextData
              ?
              <NavLink
                className="content-suggestion"
                to={`/content/${convertDataToUrl(nextData)}`}
                style={{ justifyContent: 'end' }}
              >
                <div style={{ display: 'flex', width: '300px', padding: '10px', textAlign: 'end', justifyContent: 'end' }}>
                  <div>
                    <div className="title">Next in timeline:</div>
                    <p>{`${nextData.title}`}</p>
                  </div>
                  <img
                    src={`/img/${nextData?.img || 'blank'}.png`}
                    style={{ height: '100px', margin: '10px' }}
                    alt={`Cover art of ${data?.title}`}
                  />
                </div>
              </NavLink>
              : <p />
          }
        </div>
        <div className="infopanel-inner blog-body" style={{ flexDirection: 'column' }}>
          <div className="title comment-text">Comments</div>
          {
            this.state?.comments?.length > 0
              ? this.state.comments.map((x, i) => {
                return (
                  <div className='comment' key={`comment-${data?.media_id}-${i}`}>
                    <div className='header-box'>
                      <NavLink
                        className='link-text'
                        to={`/profile/${x.username}`}
                        style={{ paddingLeft: '2px' }}
                      >
                        {`${x.username}`}
                      </NavLink>
                      <div className='small-text'>
                        {`${new Date(x.comment_date).toLocaleString()}`}
                      </div>
                    </div>
                    {
                      x.comment.split('\n').map((line, j) => {
                        return (
                          <div className='comment-text' key={`comment-${data?.media_id}-${i}-${j}`}>{line}</div>
                        )
                      })
                    }
                    {
                      this.props.username && x.username !== this.props.username
                        ? <Icon
                          className={`small-icon clickable ${x.like_users.includes(this.props.username) ? 'selected' : ''}`}
                          path={mdiThumbUp}
                          onClick={() => this.likeComment(x.comment_id, !x.like_users?.includes(this.props.username))}
                        />
                        : <Icon
                          className='small-icon'
                          path={mdiThumbUp}
                        />

                    }
                    <div className='small-text'>
                    {
                      x.like_users && x.like_users.filter(x => x).length > 0
                      ?
                        (x.like_users?.includes(this.props.username) 
                        ? (x.like_users?.filter(x => x && x !== this.props.username)?.length > 0
                          ? `Liked by you and ${x.like_users?.filter(x => x && x !== this.props.username)?.length} others`
                          : 'Liked by you'
                        )
                        : `Liked by ${x.like_users?.filter(x => x)?.length} users`)
                      : ''
                    }</div>
                  </div>
                )
              })
              : this.props?.loggedIn ? <div className='comment-text'>Be the first to add a comment!</div> : <div />
          }
          {
            this.props?.loggedIn
              ? <div>
                <textarea className="commentInput" id={`comment-box-${data?.media_id}`} />
                {
                  this.state?.commentErr
                    ? <p>ERROR: {this.state?.commentErr}</p>
                    : <div />
                }
                <button style={{ display: 'block' }} onClick={this.submitComment}>Add Comment</button>
              </div>
              : <div className='comment-text'>Log in to your account to add comments!</div>
          }
        </div>
      </div>
    );
  }
}

export default MediaPage;
