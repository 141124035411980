import React from 'react';
import './App.css';
import BlogEntry from './BlogEntry';

const RECOMMENDED_BOOKS = [
  {
    "media_id": 3,
    "body": "There's perhaps no better starting point than the first novel of a whole new era of Star Wars! This story takes place hundreds of years before the movies and introduces us to the High Republic era. Where the prequels show the fall of the Jedi Order, here we get to see them at their peak as the heroes of peace and justice they are meant to be. It tells an excellent story on it's own, and if you like it there's many more great High Republic novels and comics that follow it.",
  },
  {
    "media_id": 271,
    "body": "Tying in pretty closely to Rebels season three, this novel tells the story of Thrawn's rise to power within the Empire. It also shows how Arihnda Pryce rose to the position of Lothal's governor as we see in the show. There's plenty of scheming and tactics throughout the novel that can be quite entertaining. This is the first of six Thrawn novels in canon, so if you really enjoy it there's plenty more for you to read!",
  },
  {
    "media_id": 712,
    "body": "Possibly the most popular young adult novel of the new canon, this book tells the story of a young couple caught on opposite sides of the war. The story covers many familiar events from the original trilogy and gives us a glimpse of the pivotal Battle of Jakku. It's a great story with a lot of heart that gives a fresh perspective on the original trilogy that created the universe we love. If you like this, consider reading Bloodline or Master and Apprentice by the same author.",
  },
  {
    "media_id": 267,
    "body": "If you count Andor or Rogue One among your favourite Star Wars content, this is definitely the book for you. Luceno is well known among Star Wars fans for being the best at writing exciting politics. This book features Galen Erso and Director Krennic and fills in many details about their lives leading up to Rogue One, such as how Orson was able to manipulate Galen into designing the weapon.",
  }
]

const RECOMMENDED_COMICS = [
  {
    "media_id": 239,
    "title": "Darth Vader (2017) by Charles Soule",
    "body": "This series tells stories of Darth Vader's missions immediately after Episode III: Revenge of the Sith. It's not surprising that a big focus of the story is hunting down former Jedi, including some familiar faces. Additionally, there's other plot developments like getting his red lightsaber, meeting the inquisitors, and building his castle on Mustafar. All 25 issues have the same writer and artist so it's a very consistent reading experience from start to finish. If you like Soule's writing, you may also enjoy his Obi-Wan and Anakin or Lando miniseries.",
  },
  {
    "media_id": 436,
    "title": "Star Wars (2015) by Jason Aaron",
    "body": "The first canon comic published by Marvel also happens to be a great place to start. This series does a great job capturing the feeling of the original trilogy and the characters are written very well. There's plenty of memorable stories like Luke dueling Boba Fett, the flashbacks of Obi-Wan in exile, and the fight against SCAR Squadron. This is a great way to get started, and the crossovers with Darth Vader or Doctor Aphra give a taste of two other series that may interest you."
  },
  {
    "media_id": 218,
    "title": "Darth Maul: Son of Dathomir",
    "body": "This is a must read for any fans of The Clone Wars show. This story adapts four unproduced episodes of The Clone Wars that tell what happened to Darth Maul between being defeated by Palpatine in season five and being back on Mandalore in season seven. Needless to say, there's a ton of action, lightsaber battles, and big plot developments. If this still doesn't totally scratch your Darth Maul itch, check out the Darth Maul miniseries by Cullen Bunn.",
  },
  {
    "media_id": 325,
    "title": "Kanan",
    "body": "This series explores Kanan's life as a padawan, long before his appearance in A New Dawn and Rebels. The first story arc shows how he survived Order 66, while the second arc goes further back and shows some of his first battles in the Clone Wars. This series ties in very well with the show, with Rebels directly referencing some of the scenes from this comic. It is very well written, with excellent dialog and many themes recurring throughout the series. The art is beautiful and this remains one of the most visually impressive Star Wars comics yet."
  },
  // {
  //   "media_id": 437,
  //   "title": "Darth Vader (2015) by Kieron Gillen",
  //   "body": "Another Darth Vader comic series makes my list, this one set after Episode IV: A New Hope. Here we see a more mature and strategic Vader than in Soule's series, who works towards increasing his personal powerbase rather than directly serving the Empire. Along the way, he recruits the mysterious archaeologist Doctor Aphra, who became an incredibly popular character and has had multiple comic series' of her own. In the aftermath of the Death Star's destruction, Palpatine seeks alternatives for his apprentice, presenting Vader with a new supply of rivals.",
  // },
]

class StartingPoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const structuredDataText = [{
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "Best Star Wars Canon Books to Start With",
      "image": [
        "https://swtimeline.net/img/3.png"
      ],
      "datePublished": "2023-02-24T08:00:00+08:00",
      "dateModified": "2023-11-25T09:20:00+08:00",
      "author": [{
        "@type": "Person",
        "name": "Jake Loftus",
        "url": "https://swtimeline.net"
      }],
    },
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "Best Star Wars Canon Comics to Start With",
      "image": [
        "https://swtimeline.net/img/234.png"
      ],
      "datePublished": "2023-02-24T08:00:00+08:00",
      "dateModified": "2023-11-25T09:20:00+08:00",
      "author": [{
        "@type": "Person",
        "name": "Jake Loftus",
        "url": "https://swtimeline.net"
      }],
    }];
    const script = document.getElementById('custom-ld-json');
    script.textContent = JSON.stringify(structuredDataText);
  }

  render() {
    return (
      <div className="blog-inner">
        <div className="blog-header">
          <h1>Best Star Wars Canon Books to Start With</h1>
        </div>
        {
          RECOMMENDED_BOOKS.map(x => {
            return (
              <BlogEntry
                data={this.props.data.find((media) => media.media_id === x.media_id)}
                blogData={x}
                key={`recommended-${x.media_id}`}
                loggedIn={this.props.loggedIn}
                addRating={this.props.addRating}
              ></BlogEntry>
            )
          })
        }
        <div className="blog-header">
          <h1>Best Star Wars Canon Comics to Start With</h1>
        </div>
        {
          RECOMMENDED_COMICS.map(x => {
            return (
              <BlogEntry
                data={this.props.data.find((media) => media.media_id === x.media_id)}
                blogData={x}
                key={`recommended-${x.media_id}`}
                loggedIn={this.props.loggedIn}
                addRating={this.props.addRating}
              ></BlogEntry>
            )
          })
        }
      </div>
    );
  }
}

export default StartingPoints;
