

import React from 'react';
import { ALL_FILTERS } from './Common';
import './App.css';

class Filters extends React.Component {
  render() {
    const { filters, addFilter, searchText, setSortField, sortField } = this.props;

    return (
      <div>
        {
          ALL_FILTERS.map((x, i) => {
            return (
              <div
                className="input-container"
                key={`container-${x.name}`}
                style={i > 0 && x.desc[0] !== ALL_FILTERS[i - 1].desc[0] ? { 'marginTop': '15px' } : {}}>
                <input
                  type="checkbox"
                  id={x.name}
                  onClick={() => addFilter()}
                  defaultChecked={filters.includes(x.name)}
                />
                {x.desc}
              </div>
            )
          })
        }
        <div className="input-container" style={{ 'marginTop': '15px' }}>
          <input
            type="radio"
            id='sort_order'
            name='sort_field'
            onClick={() => setSortField('sort_order')}
            defaultChecked={sortField === 'sort_order'}
          />
          Order chronologically
        </div>
        <div className="input-container">
          <input
            type="radio"
            id='release_date'
            name='sort_field'
            onClick={() => setSortField('release_date')}
            defaultChecked={sortField === 'release_date'}
          />
          Order by release date
        </div>
        <div className="input-container">
          <input
            type="radio"
            id='avg_rating'
            name='sort_field'
            onClick={() => setSortField('avg_rating')}
            defaultChecked={sortField === 'avg_rating'}
          />
          Order by highest rated
        </div>
        <div className="input-container" style={{ marginTop: '20px' }}>
          <label>Search titles</label>
          <input
            type="text"
            id="search-text"
            onChange={() => addFilter()}
            value={searchText ? searchText : ''}
          />
        </div>
      </div>
    );
  }
}

export default Filters;
