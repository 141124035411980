import React from 'react';
import { RATING_LIST } from './Common';

class RatingsSelect extends React.Component {
  render() {
    const data = this.props.data;

    if (!data) {
      return null;
    }

    return (
      <div>
        {
          // display different information for released and unreleased media
          data?.release_date && (new Date(data?.release_date) <= new Date() || data?.format === 'C')
            ?
            <div>
              <div style={{
                display: data?.avg_rating
                  ? 'inline-flex'
                  : 'none',
                alignItems: 'baseline'
              }}>
                Average rating:
                {
                  RATING_LIST.map(x => {
                    return (
                      <div
                        style={{ display: 'inline' }}
                        key={`rating-${x}`}
                      >
                        {x < parseFloat(data?.avg_rating) + 0.5 ? '★' : '☆'}
                      </div>
                    )
                  })
                }
                ({parseFloat(data?.avg_rating).toFixed(1)})
                <div style={{ 'fontSize': 'small' }}>&nbsp;({data?.rating_count} ratings)</div>
              </div>
              <div>
                Your Rating:
                {
                  this.props.loggedIn ?
                    <select
                      name="ratingSelect"
                      id={`rating-select-${data.media_id}`}
                      onChange={(e) => this.props.addRating(data.mediaIdList, e.target.value)}
                      value={(parseInt(parseFloat(data?.user_rating) + 0.5)) || 1}
                    >
                      {
                        RATING_LIST.map(x => {
                          return (
                            <option
                              value={x}
                              key={`rating-option-${x}`}
                            >
                              {
                                RATING_LIST.map(y => {
                                  return (
                                    y <= x ? '★' : '☆'
                                  )
                                })
                              }
                              ({x})
                            </option>
                          )
                        })
                      }
                    </select>
                    : <i>&lt;You must be logged in to rate&gt;</i>
                }
              </div>
            </div>

            // hide ratings for unreleased media
            : <div />
        }
      </div>
    );
  }
}

export default RatingsSelect;
