

import React from 'react';
import { MEDIA_TYPE_MAP } from './Common.js'
import TimelineElement from './TimelineElement';
import './App.css';

class Legend extends React.Component {
  render() {

    return (
      <div>
        {
          Object.keys(MEDIA_TYPE_MAP).map(x => {
            return (
              <TimelineElement
                data={{
                  'title': MEDIA_TYPE_MAP[x],
                  'format': x
                }}
                key={`legend-sample-${x}`}
              >
              </TimelineElement>
            )
          })
        }
      </div>
    );
  }
}

export default Legend;
