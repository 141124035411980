import React from 'react';
import Modal from 'react-modal';
import '../App.css';

class BaseModal extends React.Component {
  render() {
    const content = this.props.content;
    const isOpen = this.props.isOpen;
    const onRequestClose = this.props.onRequestClose;
    const title = this.props.title;

    return (
      <Modal
        ariaHideApp={false}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
      >
        <button onClick={onRequestClose} className="modal-button">✕</button>
        <div className="title">{title}</div>
        <div>
          {content}
        </div>
      </Modal>
    );
  }
}

export default BaseModal;
