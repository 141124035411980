import React from 'react';
import { openUrl, eventGuideData, convertDataToUrl } from './Common';
import RatingsSelect from './RatingsSelect';
import { NavLink } from "react-router-dom";
import EraIcon from './EraIcon';


class MediaDetailPanel extends React.Component {
  render() {
    const data = this.props.data;
    let eventGuidePath;
    Object.entries(eventGuideData).map(([path, guideData]) => {
      if (data?.collection_title?.includes(guideData.title)) {
        eventGuidePath = path;
      }
    });

    if (!data) {
      return null;
    }

    return (
      <div className="infopanel-wrapper">
        <div className="infopanel">
          <div className="infopanel-inner header-box">
            <div>
              <button onClick={this.props.onClose} className="modal-button">✕</button>
              <div className="title">{data?.title}</div>
              <div>{data?.subtitle}</div>
              <RatingsSelect
                data={data}
                loggedIn={this.props.loggedIn}
                addRating={this.props.addRating}
              >
              </RatingsSelect>
              <div>
                {
                  data?.release_date
                    ? `Release date: ${new Date(data?.release_date).toISOString().slice(0, 10)}`
                    : 'Not yet released'
                }
              </div>
            </div>
            <EraIcon data={data} />
          </div>
          <div className="infopanel-inner blog-body" style={{ 'height': '77%', overflowY: 'scroll' }}>
            <div className="image">
              <img
                src={`/img/${data?.img || 'blank'}.png`}
                style={{ height: '100%' }}
                alt={`Cover art of ${data?.title}`}
              />
              {
                data?.amazon
                  ? <button
                    onClick={() => openUrl(data.amazon)}
                  >
                    View on Amazon
                  </button>
                  : <div />
              }
            </div>
            <div>
              <p>{data?.collected_issues}</p>
              <p>{data?.summary || 'Plot summary coming soon...'}</p>
              <p>{data?.notes}</p>
              {
                eventGuidePath
                  ? <NavLink to={`/${eventGuidePath}`}>
                    <button>View Event Guide</button>
                  </NavLink>
                  : <div />
              }
              <NavLink
                to={`/content/${convertDataToUrl(data)}`}
              >
                <button>View Details or Add Comments</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MediaDetailPanel;
