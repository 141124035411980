import React from 'react';
import { ERA_MAP } from './Common';

class EraIcon extends React.Component {
  render() {
    const data = this.props.data;

    if (!data?.era) {
      return null;
    }

    return (
      <div className="image era-icon">
        <img
          src={`/img/era-${data?.era}.png`}
          style={{ height: '100%' }}
          alt={`Takes place in the ${ERA_MAP[data?.era]} era`}
        />
        {ERA_MAP[data?.era]}
      </div>
    );
  }
}

export default EraIcon;
