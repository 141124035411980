import React from 'react';
import './App.css';
import { openUrl, convertDataToUrl } from './Common';
import { NavLink } from "react-router-dom";


class ListDisplayPanel extends React.Component {
  render() {
    const valueField = this.props.valueField;
    // get the top N values
    const selectedRows = this.props.selectedRows || 10;
    const data = this.props.data?.sort((a, b) => b[valueField] - a[valueField]).slice(0, selectedRows);
    const title = this.props.title;

    if (!data.length) {
      return null;
    }

    return (
      <div className="profile-panel">
        <div className="blog-body">
          <div style={{ 'width': '100%' }}>
            <div className="subtitle">{title}</div>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                {
                  data.map(x => {
                    return (
                      <tr key={`top-ratings-${x.title}`}>
                        <td style={{ 'textAlign': 'right' }}>
                          {`${parseFloat(x[valueField]).toFixed(1)}`}
                        </td>
                        <td>
                          <NavLink
                            className='link-text'
                            to={`/content/${convertDataToUrl(x)}`}
                            style={{ display: 'block' }}
                          >
                            {`${x.title}`}
                          </NavLink>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="image" style={{ 'height': '220px', 'width': '170px' }}>
            <img
              src={`/img/${data[0]?.img || 'blank'}.png`}
              style={{ height: '100%' }}
              alt={`Cover art of ${data[0]?.title}`}
            />
            {
              data[0]?.amazon && valueField !== 'user_rating'
                ? <button
                  className="blog-button"
                  onClick={() => openUrl(data[0].amazon)}
                >View on Amazon</button>
                : <div />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ListDisplayPanel
