import React from 'react';
import { openUrl } from './Common';
import './App.css';
import RatingsSelect from './RatingsSelect';

class BlogEntry extends React.Component {
  render() {
    const data = this.props.data;
    const blogData = this.props.blogData;

    return (
      <div className="blogEntry infopanel-inner">
        <div className="title">{blogData?.title || data?.title}</div>
        <div>{blogData?.review_date ? `Posted by Jake Loftus on ${blogData?.review_date?.split('T')?.[0]}` : ''}</div>
        <div>
          {
            data ?
              <RatingsSelect
                data={data}
                loggedIn={this.props.loggedIn}
                addRating={this.props.addRating}
              /> : <div />
          }
        </div>
        <div
          className='blog-body'
          style={data?.media_id % 2 === 0 ? {} : { flexDirection: 'row-reverse' }}
        >
          <div className="image">
            <img
              loading="lazy"
              src={`/img/${blogData?.img || data?.img || 'blank'}.png`}
              style={{ height: '100%' }}
              alt={`Cover art of ${data?.title}`}
            />
            {
              blogData?.amazon || data?.amazon
                ? <button
                  className="blog-button"
                  onClick={() => openUrl(blogData.amazon || data.amazon)}
                >View on Amazon</button>
                : <div />
            }
          </div>
          <div
            className="blog-text"
          >
            {
              blogData.body?.split('\n')?.map((x, index) => {
                return (
                  <p
                    key={`blog-text-${index}`}
                  >
                    {x}
                  </p>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}

export default BlogEntry;
