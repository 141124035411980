import React from 'react';
import BaseModal from './BaseModal';
import '../App.css';

class SignupModal extends React.Component {
  render() {
    const { isOpen, onRequestClose, handleLogin, signupErr } = this.props;

    return (
      <BaseModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title="Register"
        content={
          <div className="form">
            <div className="input-container">
              <label>Username </label>
              <input type="text" id="uname" />
            </div>
            <div className="input-container">
              <label>Password </label>
              <input type="password" id="pass" />
            </div>
            <div className="input-container">
              <label>Confirm Password </label>
              <input type="password" id="pass-confirm" />
            </div>
            <div className="input-container">
              <label>Email </label>
              <input type="text" id="email" />
            </div>
            <p>Your email will only ever be used for password resets.</p>
            <p style={{ 'color': 'red', 'width': '300px' }}>
              {signupErr}
            </p>
            <div className="button-container">
              <button onClick={() => handleLogin('signup')}>Signup</button>
            </div>
          </div>
        }
      >
      </BaseModal>
    );
  }
}

export default SignupModal;
